import {
  FormControl,
  MenuItem,
  Modal,
  Select,
  FormHelperText,
  Typography,
} from '@mui/material'
import { Inventory2 } from '@mui/icons-material'

import { LoadingButton } from 'components/mui'

import { useChooseTrayLocationModal } from './ChooseTrayLocationModal.logic'

import './ChooseTrayLocationModal.scss'

export const ChooseTrayLocationModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const {
    findLocation,
    selectedLocationId,
    trayData,
    handleSPDDataLocationChange,
    locationsWithParents,
    updating,
    handleUpdateTrayItem,
  } = useChooseTrayLocationModal({ onClose })

  return (
    <Modal open={open} onClose={onClose}>
      <div
        data-testid="choose-tray-location_modal"
        className="choose-tray-location_modal"
      >
        <div
          data-testid="choose-tray-location_modal-header"
          className="header-box"
        >
          <Inventory2 />

          <Typography variant="h3">Choose Tray Storage Location</Typography>
        </div>

        <FormControl fullWidth>
          <Select
            fullWidth
            data-testid="choose-tray-location-modal_picker"
            variant="outlined"
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <Typography variant="body1" color="gray">
                    Select Tray Storage Location (required)
                  </Typography>
                )
              }

              return findLocation(selected as number)?.name
            }}
            value={selectedLocationId}
            defaultValue={trayData?.trayItem.locationId}
            onChange={handleSPDDataLocationChange}
          >
            <MenuItem disabled value="">
              Select Tray Storage Location
            </MenuItem>

            {locationsWithParents.map((location) => (
              <MenuItem
                data-testid="hardware-menuitem"
                style={{ cursor: 'pointer', fontSize: 16 }}
                key={location.id}
                value={location.id}
              >
                {location.name},{' '}
                {findLocation(location.parentLocationId as number)?.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText data-testid="choose-tray-location-modal_picker_helper-text">
            You can create new locations from the location management dashboard
          </FormHelperText>
        </FormControl>

        <LoadingButton
          dataTestId="save-tray-storage-location_button"
          disabled={
            updating || (!selectedLocationId && !trayData?.trayItem.locationId)
          }
          loading={updating}
          onClick={() => handleUpdateTrayItem()}
        >
          Save Location
        </LoadingButton>
      </div>
    </Modal>
  )
}
